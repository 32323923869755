import CssBaseline from '@material-ui/core/CssBaseline'
import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
//import reportWebVitals from './reportWebVitals'
import Viewer from './Viewer'

declare global {
  interface Window {
    VirtualBackgroundBridge: any;
    VirtualBackgroundBridgeOpt: any;
    VirtualBackgroundBridgeImgElement: any;
    VirtualBackgroundBridgeCBK: any;
    VirtualBackgroundBridgeTest: any;
    VirtualBackgroundMediaStreamTrack: any;
  }
}

window.VirtualBackgroundBridge = class VirtualBackgroundBridge{
  
  async loadImage(url: string) {
    return new Promise((resolve) => {
      window.VirtualBackgroundBridgeImgElement = new Image();
      window.VirtualBackgroundBridgeImgElement.src = url;
      window.VirtualBackgroundBridgeImgElement.onload = () => {
        resolve(true);
      }     
    });
  }

  async start(mediaStreamTrack: MediaStreamTrack, type: string, opt: any = {}) {
    document.body.appendChild(document.createElement('div')).id = 'root-vbg';
    (document.getElementById('root-vbg') as HTMLElement).style.zIndex = '-1';
    (document.getElementById('root-vbg') as HTMLElement).style.position = 'absolute';
    const root = createRoot(document.getElementById('root-vbg')!)
    window.VirtualBackgroundMediaStreamTrack = mediaStreamTrack;
    window.VirtualBackgroundBridgeOpt = {
      type: type,
      url: opt?.imagePath
    }

    if(opt?.imagePath) {
      await this.loadImage(opt.imagePath);
    }

    root.render(
      <App />
    )
    while(!document.getElementById('canvas')) {
      await new Promise((resolve) => setTimeout(resolve, 1000));    
    }
    console.log('ok');
  }
  
  stop() {
    window.VirtualBackgroundBridgeCBK({
      type: 'none'
    });
    document.getElementById('root-vbg')?.parentNode?.removeChild(document.getElementById('root-vbg')!);
  }

  getTrack() {
    return (document.getElementById('canvas') as HTMLCanvasElement).captureStream().getVideoTracks()[0];
  }

  getTransformer() {
    var self = this;
    return {
      async update(opt: any) {
        if(opt.imagePath) {
          await self.loadImage(opt.imagePath);        
          window.VirtualBackgroundBridgeCBK({
            type: 'image',
            url: opt.imagePath
          })
        }
        else {
          window.VirtualBackgroundBridgeCBK({
            type: 'blur'
          })
        }
      }
    }  
  }

}

window.VirtualBackgroundBridgeTest = class VirtualBackgroundBridgeTest{

  async test() {
    const constraint = { video: true }
    const stream = await navigator.mediaDevices.getUserMedia(constraint)
    let vb = new window.VirtualBackgroundBridge();
    await vb.start(stream.getVideoTracks()[0], 'image', {
      imagePath: '/virtual-background/backgrounds/architecture-5082700_1280.jpg'
    });
    console.log('started');

    setTimeout(() => {
      vb.getTransformer().update({
        type: 'blur'
      });
    }, 5000);

    setTimeout(() => {
      vb.getTransformer().update({
        type: 'image',
        imagePath: '/virtual-background/backgrounds/saxon-switzerland-539418_1280.jpg'
      });
    }, 10000);

    setTimeout(() => {
    //  vb.stop();
    }, 15000);

    setTimeout(() => {
    //  vb.start(stream.getVideoTracks()[0], 'blur');
    }, 20000);
  }

}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals()
